import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
class Aboutus extends Component {
    render() {
        return (
            <div>
                <div className="about-us">
                    <Container fluid>
                        <div className="about_section">
                            <Row>
                                <Col xl={12} lg={12} sm={12} md={12}>
                                    <div className="aboutus-container">
                                        <h1 className="header-title" >ABOUT US</h1>
                                        <p className="content">LocaliseM Technologies is a software development company which confers impressive IT solutions to make the products digitally astonishing and works smarter than you think. We @ LocaliseM works in converting all local business into digital medium and automate the manual process into the systematic flow to ensure the business grows more profitable and stand out. Digitalizing can help the business grow in multiple ways and keep your people productive. </p>
                                        <p className="content">LocaliseM, offers multitude of services to capacitate your business into any platform with simple yet potent solutions. Now, IT solutions are not complicated as it was in the decade before, newer technologies offering newer solutions in the cost-effective approach. We @ LocaliseM wants every business to utilize the digital platform to provide the greatest service to their client with competitive price. In this modern era, digitalizing can take your business to the next level without compromising the trust and the quality. </p>
                                        <p className="content">LocaliseM, we ensure to bestow the remarkable IT solutions for any range of businesses locally and globally.</p>
                                        <p className="content">We believe in</p>
                                        <p className="content about-content-subtitle" >1. Transparency </p>
                                        <p className="content about-content-subtitle" >2. Security</p>
                                        <p className="content about-content-subtitle" >3.	Flexibility</p>
                                        <p className="content about-content-subtitle" >4. Quality</p>
                                        <p className="content about-content-subtitle" >5. Sustainability</p>
                                        <p className="content" >Localisem is founded by Mr. Muraliraj Ranganathan. He has the experience in IT industry in the USA for 10+ years. His excellency in problem solving and giving smarter solutions is major reason behind building Localisem, a global IT company, which gives smarter solutions for different class of businesses. His vision is to primarily develop systematic flow, giving high end IT solutions for local vendors to improve their business digitally to next level, as the world and our nation becoming more digital in this era he believes digitalization from the root is very important for country’s major development. People always think IT solutions are very complicated and so the price is, but here in Localisem Giving smarter solutions for smarter price is his motto. With the easy to approach ideology he (Localisem) confers Simple yet brilliant business solutions in cost effective plans under any budgets.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Aboutus;