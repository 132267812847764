import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Image,Row,Col,Container} from 'react-bootstrap';
import farmer from '../assets/images/icon-packs/farmer-green.svg';
import security from '../assets/images/icon-packs/security-green.svg';
import pricing from '../assets/images/icon-packs/pricing-green.svg';
import payment from '../assets/images/icon-packs/payment-green.svg';
import experience from '../assets/images/icon-packs/experience-green.svg';
import expertise from '../assets/images/icon-packs/excellence-green.svg';
import delivery from '../assets/images/icon-packs/delivery-green.svg';
import document from '../assets/images/icon-packs/document-green.svg';
import expert from '../assets/images/icon-packs/expert-green.svg';
import meeting from '../assets/images/icon-packs/meeting-green.svg';
import shop from '../assets/images/icon-packs/shop-green.svg';
import problem from '../assets/images/icon-packs/problem-green.svg';
import maintenance from '../assets/images/icon-packs/maintenance-green.svg';
import speaks from '../assets/images/icon-packs/speaks-green.svg';
import statisfaction from '../assets/images/icon-packs/statisfaction-green.svg';
import '../styles/owl-carousel.css';
const options = {
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        800: {
            items: 3,
        },
        900: {
            items: 4,
        },
        1000: {
            items: 4,

        }
    },
};


class WhyLMCarousel extends Component {
    render() {
        return (
            <div>
                <div className="whylm d-flex align-items-center">
                    <Container fluid>
                        <div className="whylm-container">
                            <h1 className="header-title">Why Localisem</h1>
                            <p className="content text-center">Localisem Technologies fervently craft attractive and impressive software solutions like Website development, Mobile App Development, BigData Development etc to increase your brand growth globally and digitally.</p>
                            <Row><Col xl={12} >
                                <OwlCarousel
                                    className="owl-theme"
                                    refreshClass='owl-refresh'
                                    autoHeightClass='owl-height'
                                    navContainerClass='owl-nav'

                                    responsiveClass={true}
                                    loop={true}
                                    items={2}
                                    margin={20}
                                    mergeFit={true}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    pullDrag={true}
                                    autoplay={true}
                                    autoplaySpeed={1000}
                                    navSpeed={true}
                                    video={true}
                                    autoplayTimeout={2500}
                                    autoplayHoverPause={true}
                                    lazyLoad={true}
                                    onLoadedLazy={true}
                                    dragEndSpeed={1000}
                                    responsiveClass={true}
                                    responsive
                                    slideBy={1}
                                    {...options}
                                >
                                    <div className="why-item">
                                        <Image src={farmer} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Complimentary Services</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={security} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Security</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={pricing} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Pricing</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={payment} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Payment</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={experience} className="why-lm-icon" />
                                        <h1 className="why-lm-header"> Skilled and Experienced</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={expertise} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Deep Industry Excellence</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={delivery} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Delivery</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={document} className="why-lm-icon" />
                                        <h1 className="why-lm-header"> Documentation</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={expert} className="why-lm-icon" />
                                        <h1 className="why-lm-header">R&D</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={meeting} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Deadlines</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={shop} className="why-lm-icon" />
                                        <h1 className="why-lm-header">One Stop Solutions</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={problem} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Solving The Business Problems</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={maintenance} className="why-lm-icon" />
                                        <h1 className="why-lm-header">Post Launch Support and Maintenance</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={speaks} className="why-lm-icon" />
                                        <h1 className="why-lm-header">No Geek Speaks</h1>
                                    </div>
                                    <div className="why-item">
                                        <Image src={statisfaction} className="why-lm-icon" />
                                        <h1 className="why-lm-header">100% Satisfaction Guarantee</h1>
                                    </div>
                                </OwlCarousel>
                            </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default WhyLMCarousel;