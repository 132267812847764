import React,{Component} from 'react';
import {BrowserRouter, Route,Switch} from 'react-router-dom';
import Home from './pages/home';
import Company from './pages/company';
import Service from './pages/service';
import Technology from './pages/technology';
import NotFound from './pages/404.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";
import './styles/home.css';
class App extends Component {

 
  render(){
    return(

        <React.Fragment>
    <BrowserRouter>
    <Switch>
    <Route exact path="/" component={Home}/> 
    <Route path="/company" component={Company}/>
    <Route path="/service" component={Service}/>
    <Route path="/technology" component={Technology}/>
    <Route path="*" component={NotFound} />
    </Switch>
    </BrowserRouter>
    </React.Fragment>
   
    )
  }
}

export default App;