import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Favicon from '../favicon.ico';
import LMLogo from '../Localisem-Logo.svg';
import Layout from '../components/layout_sub';
import cycling from '../assets/images/cycling.svg';
import { Link } from 'react-router-dom';
import techimage from '../assets/images/techheader.svg';
import TechnologyCarousel from '../components/technologycarousel';
import '../styles/technology.css';
import Header from '../components/header';
import Footer from '../components/footer';
import { NavHashLink } from 'react-router-hash-link';
class Technologies extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
              
                    <Helmet>
                        <meta charSet="UTF-8" />
                        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                        <title>Technologies | Cutting Edge Technologies | iOS and Android | React and Flutter</title>
                        <meta name="description" content="Our mission is to build connections between our clients and their potential customer base by creating a standard of excellence and providing top notch service with help of industries best technologies." />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="keywords" content="web app developments,iOS app developments,app developments,software developments,mac app developments localisem,localism" />
                        <link rel="canonical" href="https://www.localisem.com/technology" />
                        <meta property="og:url" content="https://www.localisem.com" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Technologies | Cutting Edge Technologies | iOS and Android | React and Flutter" />
                        <meta property="og:description" content="Our mission is to build connections between our clients and their potential customer base by creating a standard of excellence and providing top notch service with help of industries best technologies." />
                        <meta property="og:site_name" content="https://www.localisem.com" />
                        <link rel="shortcut icon" sizes="16x16" href={Favicon} />
                        <link rel="shortcut icon" sizes="24x24" href={Favicon} />
                        <link rel="shortcut icon" sizes="32x32" href={Favicon} />
                        <link rel="shortcut icon" sizes="64x64" href={Favicon} />
                        <link rel="icon" href={Favicon} />
                        <link rel="apple-touch-icon" href={LMLogo} />
                        <link rel="apple-touch-icon-precomposed" href={LMLogo} />
                    </Helmet>
              <Layout>
                    <div className="technology">
                        <div className="technology_sec">
                            <div className="tech-header" id={'technology'}>
                                <div className="tech-header-section">
                                    <Container fluid>
                                        <Row className="tech-header-rows">

                                            <Col className="my-auto" lg={6}><h1 className="header-title">Cutting-Edge Technologies</h1>
                                                <p className="content">Our mission is to build connections between our clients and their potential customer base by creating a standard of excellence and providing top notch service with help of industries best technologies. </p></Col>

                                            <Col className="my-auto" lg={6}><Image className="techimage" src={techimage} alt="headerimage" /></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>

                            <div clasName="technology_Carousel">
                                <TechnologyCarousel />
                            </div>

                            <div className="cycling" >

                                <Container fluid>
                                    <div className="cycling-section">
                                        <Row>
                                            <Col xl={12}>
                                                <h1 className="header-title" > Development Process </h1>
                                                <p className="content text-center">A development process is usually divided into two weeks sprints. Each sprint covers the scope of work essential for shipment of a finished part of app functionality. The product owner sees the immediate result of completed work, tests it, and makes changes on the project, if needed.</p>
                                                <div className="my-auto development-architecture" ><Image src={cycling} alt="Cycling" /></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </div>

                            <div className="tech_offers">

                                <Container fluid>
                                    <Row>
                                        <Col xl={12}><h1 className="tech_offers_content text-center">We have been in this industry for more than a decade. We are core hard code developers and more proficient to choose the right technical stack to build your product, for more insights about technologies, Please <NavHashLink to="/#contact" style={{ color: '#0d25af', fontWeight: '600', textDecoration: 'none' }}>Contact Us</NavHashLink>.</h1></Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
               </Layout>
            </div>
        );
    }
}

export default Technologies;