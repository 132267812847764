import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Favicon from '../favicon.ico';
import LMLogo from '../Localisem-Logo.svg';
import Layout from '../components/layout_sub';
import How from '../components/howwework';
import FAQ from '../components/faq';
import Why from '../components/whychooseus';
import Aboutus from '../components/about';
import Partner from '../components/partners';
import { Link, animateScroll as scroll } from 'react-scroll'
import '../styles/company.css';
import Header from '../components/header';
import Footer from '../components/footer';
class Company extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false, sidemenu: true }
    }
    handle = () => {
        this.setState({ show: !this.state.show });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <title>Company | Software Development Company in Tirupattur, Chennai, India</title>
                    <meta name="description" content="𝗟𝗼𝗰𝗮𝗹𝗶𝘀𝗲𝗺 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗲𝘀 is a software development company which confers impressive IT solutions to make the products digitally astonishing and works smarter than you think." />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="keywords" content="web app developments,iOS app developments,app developments,software developments,mac app developments localisem,localism" />
                    <link rel="canonical" href="http://localisem.com/company" />
                    <meta property="og:url" content="http://localisem.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Company" />
                    <meta property="og:description" content="𝗟𝗼𝗰𝗮𝗹𝗶𝘀𝗲𝗺 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗲𝘀 is a software development company which confers impressive IT solutions to make the products digitally astonishing and works smarter than you think." />
                    <meta property="og:site_name" content="http://localisem.com" />
                    <link rel="shortcut icon" sizes="16x16" href={Favicon} />
                    <link rel="shortcut icon" sizes="24x24" href={Favicon} />
                    <link rel="shortcut icon" sizes="32x32" href={Favicon} />
                    <link rel="shortcut icon" sizes="64x64" href={Favicon} />
                    <link rel="icon" href={Favicon} />
                    <link rel="apple-touch-icon" href={LMLogo} />
                    <link rel="apple-touch-icon-precomposed" href={LMLogo} />
                </Helmet>
                <Layout>
                    <div className="company">
                        <div class="icon-bar sidebar-sticky sticky" id="sidebar">
                            <Link className="sec-link" activeClass="active-scroll" to="how" offset={-50} spy={true} smooth={true} duration={0}>How We Work</Link>
                            <Link className="sec-link" activeClass="active-scroll" to="why" offset={-50} spy={true} smooth={true} duration={0} >Why Choose Us</Link>
                            <Link className="sec-link" activeClass="active-scroll" to="aboutus" offset={-50} spy={true} smooth={true} duration={0} >About Us</Link>
                            <Link className="sec-link" activeClass="active-scroll" to="partner" offset={-50} spy={true} smooth={true} duration={0} >Partner</Link>
                            <Link className="sec-link" activeClass="active-scroll" to="faq" offset={-50} spy={true} smooth={true} duration={0} >FAQ</Link>
                            <Link className="sec-link" activeClass="active-scroll" offset={-50} onClick={this.handle} spy={true} smooth={true} duration={0} >Careers</Link>
                        </div>
                        <div className="how">
                            <div className="howwework" id="how">
                                <How />
                            </div>
                            <div className="whychooseus" id="why">
                                <Why />
                            </div>
                            <div className="aboutus" id="aboutus">
                                <Aboutus />
                            </div>
                            <div className="partner" id="partner">
                                <Partner />
                            </div>
                            <div className="faq" id="faq">
                                <FAQ />
                            </div>
                        </div>
                        <Modal
                            show={this.state.show}
                            aria-labelledby="contained-modal-title-vcenter"
                            onHide={this.handle}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter"><h2 className="career-title">Careers</h2></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="content career_content">Thanks for reaching to us. Kindly share your details to <span style={{ color: 'blue' }}>contact@localisem.com </span> and our team will respond to you</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button block variant="primary" onClick={this.handle}>
                                    Close
                                 </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default Company;