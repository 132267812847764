import React, { useContext } from "react";
import { Accordion, Card, useAccordionToggle, Container, AccordionContext,Row,Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas, faCheckSquare, faCoffee)



function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <FontAwesomeIcon icon={isCurrentEventKey ? 'minus' : 'plus'} style={{ float: 'right', width:'0.6em',height:'0.6em',color:'3b4246',fontWeight:'400' }} />
  );
}

const FAQ = () => {
  return (
    <div>
     
      <div className="faq">
       
          <Container fluid>
          <div className="faq_section" id="faq">
            <Row>
              <Col xl={12}>
                <div className="faq_sec">
            <div className="faq-header">
              <h1 className="header-title">Frequently Asked Question</h1>

            </div>


            <Accordion>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <h2 className="faq-title noselect">1. How is my idea protected?  <ContextAwareToggle eventKey="1"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body style={{ border: "0px" }}><p className="faq-content noselect">One of our first step is signing NDA by both Localisem and you.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <h2 className="faq-title noselect">2. How transparency you are? <ContextAwareToggle eventKey="2"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body style={{ border: "0px" }}><p className="faq-content noselect">Transparency is the key to develop a trustworthy bond between the client and service provider, we are highly transparent to make you comfortable and meet your needs. We let you know what’s happening in your project in a detailed report before and after each sprint.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  <h2 className="faq-title noselect">3. I only have an idea. Do you help with requirements? <ContextAwareToggle eventKey="3"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3" >
                  <Card.Body style={{ border: "0px" }}> <p className="faq-content noselect">Yes. Feel free to contact us even if you have only a general idea for your future digital solution. We will help you to translate your idea into a set of requirements.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  <h2 className="faq-title noselect">4. Do you provide payment terms? <ContextAwareToggle eventKey="4"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4" >
                  <Card.Body style={{ border: "0px" }}> <p className="faq-content noselect">Yes, Our Bi-Weekly delivery plan offers no rush in cash burn. You can pay upon every milestones delivered.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  <h2 className="faq-title noselect">5. How much does it cost to develop a website? <ContextAwareToggle eventKey="5"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body style={{ border: "0px" }}> <p className="faq-content noselect">Localisem hourly rate is less than $20 USD per hour for website development, the final website cost starts from $199 USD. The price will be calculated based on project complexity.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  <h2 className="faq-title noselect">6. How much does it cost to develop an app? <ContextAwareToggle eventKey="6"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6" >
                  <Card.Body style={{ border: "0px" }}> <p className="faq-content noselect">Localisem hourly rate is less than $40 USD per hour for app development, the final app cost starts from $999 USD. The price will be calculated based on project complexity.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  <h2 className="faq-title noselect">7. How much time will I need to communicate with you? <ContextAwareToggle eventKey="7"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body style={{ border: "0px" }}>  <p className="faq-content noselect">The detail discussion will help us to build the product what you are looking for, however we are also mindful of your time. Generally bi-weekly one hour of your time is more than enough for us to review and get your feedback.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="8">
                  <h2 className="faq-title noselect">8. Will you help us to market the project upon completion? <ContextAwareToggle eventKey="8"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8" >
                  <Card.Body style={{ border: "0px" }}><p className="faq-content noselect">Yes! We provide marketing consultations without additional cost to you and we always keep branding in mind when we are creating your product.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "0px" }}>
                <Accordion.Toggle as={Card.Header} eventKey="9">
                  <h2 className="faq-title noselect">9. Will you provide any delivery guarantees for all projects? <ContextAwareToggle eventKey="9"></ContextAwareToggle></h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="9">
                  <Card.Body style={{ border: "0px" }}><p className="faq-content noselect">Yes! For any project that we take on, we include a 90-day bug warranty and maintenance window. We want you to be completely satisfied with our services. We will do whatever it takes to make you happy. No hassles, no problems. All smiles.</p></Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>



</div>
</Col>
</Row>
            </div>

          </Container>

      </div>
    </div>
  );
}
export default FAQ;