import React, { Component } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import farmer from '../assets/images/icon-packs/farmer.svg';
import security from '../assets/images/icon-packs/security.svg';
import pricing from '../assets/images/icon-packs/pricing.svg';
import payment from '../assets/images/icon-packs/payment.svg';
import experience from '../assets/images/icon-packs/experience.svg';
import expertise from '../assets/images/icon-packs/expertise.svg';
import delivery from '../assets/images/icon-packs/delivery.svg';
import document from '../assets/images/icon-packs/document.svg';
import expert from '../assets/images/icon-packs/expert.svg';
import meeting from '../assets/images/icon-packs/meeting.svg';
import shop from '../assets/images/icon-packs/shop.svg';
import problem from '../assets/images/icon-packs/problem.svg';
import maintenance from '../assets/images/icon-packs/maintenance.svg';
import speaks from '../assets/images/icon-packs/speaks.svg';
import statisfaction from '../assets/images/icon-packs/statisfaction.svg';
class WhyChooseus extends Component {
    render() {
        return (
            <div>
                <div className="describe" >
                    <Container fluid>
                        <div className="describe_section" id="whychooseus">
                            <Row>
                                <Col xl={12}>
                                    <div className="describe_sec">
                                        <div className="describe-header">

                                            <h1 className="header-title" >WHY CHOOSE US</h1>
                                            <h2 className="sub-title" >Why choosing us is the best choice you can make, 15 best values we offer that amazes you</h2>
                                        </div>

                                        <Row className="describe-row" style={{ paddingTop: '40px' }}>
                                            <Col md={6}><h2 className="header-sub-title"><Image src={farmer} className="icon" /> Complimentary Services</h2>
                                                <p className="content"  >We are exclusively offering complimentary services which are totally free of cost for building up to 10 pages of static websites for the following sector clients</p>
                                                <p className="content">1.	Farmers</p>
                                                <p className="content">2.	Orphanages</p>
                                                <p className="content">3.	Food donations ( Annadhanam )</p>
                                                <p className="content"> <span style={{ fontWeight: 'bold' }}>50% Discount services -</span> We are extending our support to the registered NGOs by offering 50% discounts on all our services.</p>
                                                <h2 className="header-sub-title"><Image src={security} className="icon" /> Security</h2>
                                                <p className="content">We confidentially safe guard client’s data by signing NDA. We ensure and enforce Cyber Security Best Practices to protect all our client’s data. Our employees accessing the network are well educated and trained on our company’s security policies.  Internally as well.</p>
                                                <h2 className="header-sub-title"><Image src={pricing} className="icon" /> Pricing</h2>
                                                <p className="content">Our prices are competitive and decent. We won’t surprise you with unexpected or additional expenses without a pre-approval from you. We treat our clients like we wish to be treated. Profit for us is gaining a satisfactory smile from our clients.</p>
                                                <h2 className="header-sub-title"><Image src={payment} className="icon" /> Payment</h2>
                                                <p className="content">Never burn your money, you can have milestones through the course of your project and make payment after the delivery of each sprint.</p>
                                                <h2 className="header-sub-title"><Image src={experience} className="icon" /> Skilled & Experienced</h2>
                                                <p className="content">We worked in IT industry in the USA for more than a decade and gained experiences from worldwide clients. Given smarter solutions using industry’s top technologies. Our employees are expertise in high end technologies and mastered the development skills from the basics. When we discover that something is not feasible and aren’t fit within your expected timeline or budget, we will inform you upfront. we intend to choose honesty more than a profit and believe it is the key to successfully deliver your project on time and budget.</p>
                                                <h2 className="header-sub-title"><Image src={expertise} className="icon" /> Deep Industry Excellence</h2>
                                                <p className="content">We are excellence in industries like Health Care, Retail & wholesale, Communications, Travel, E-commerce, Banking and Education sectors. Our foundation is our ability to solve the complex IT problems. Giving simpler and smarter IT solutions for the complicated problems is our style.</p>

                                                <h2 className="header-sub-title"><Image src={delivery} className="icon" /> Delivery</h2>
                                                <p className="content">Transparency is the key to develop a trustworthy bond between the client and service provider, we are highly transparent to make you comfortable and meet your needs. We let you know what’s happening in your project in a detailed report before and after each sprint. Our timeline for a sprint is 2 weeks and ll deliver some functional piece of work every two weeks, so that you can test you project immediately and can incorporate your feedbacks in upcoming sprints if required.</p>

                                            </Col>
                                            <Col md={6}>
                                                <h2 className="header-sub-title"><Image src={document} className="icon" /> Documentation</h2>
                                                <p className="content">We love to document every process of the project. Implementing proper documentation of the project is the essential key to get it done on time. After completing each sprint, Respective client will receive the delivery with appropriate documentation to initiate the test process of the product.</p>
                                                <h2 className="header-sub-title"><Image src={expert} className="icon" /> R&D</h2>
                                                <p className="content">All our employees are done masters in CS and IT. Had experience working in advanced technologies. In LocaliseM, an unique team is formed to focus on gaining insight in development trends. Meanwhile, in a leisure time employee are stated to master their existing skills or learn new technologies, which can help developers to provide new, relevant solutions as soon as stable releases are published.</p>
                                                <h2 className="header-sub-title"><Image src={meeting} className="icon" /> Deadlines</h2>
                                                <p className="content">We practiced finishing project before the deadline and never missed one. Executing the same formula here, as we know implementing what we practice is a key to success.</p>
                                                <h2 className="header-sub-title"><Image src={shop} className="icon" /> One Stop Solutions</h2>
                                                <p className="content">We bespoke almost all aspects of IT projects like Mobile apps, websites, business software’s and technical solution needs. We focus on your IT solutions so that you can focus on your business.</p>
                                                <h2 className="header-sub-title"><Image src={problem} className="icon" /> Solving The Business Problems</h2>
                                                <p className="content">We sit on the same side of the table as YOU to provide business solutions to business problems. We don’t try to sell you our service – instead – we listen to your business problem and offer several strategies to resolve the ROOT of the problem and achieve your objectives.</p>
                                                <h2 className="header-sub-title"><Image src={maintenance} className="icon" /> Post Launch Support & Maintenance</h2>
                                                <p className="content">All our finished products are delivered to you with complementary 90 days of technical support and maintenance, we are happy to extend our support with decent cost if the client demands.  </p>
                                                <h2 className="header-sub-title"><Image src={speaks} className="icon" /> No Geek Speaks</h2>
                                                <p className="content">You deserve to have your questions answered in simple English. We will clearly explain the solutions we offer until you understand.</p>
                                                <h2 className="header-sub-title"><Image src={statisfaction} className="icon" /> 100% Satisfaction Guarantee</h2>
                                                <p className="content">We want you to be completely satisfied with our services. We will do whatever it takes to make you happy. No hassles, no problems. All smiles.</p>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>
            </div>
        );
    }
}

export default WhyChooseus;