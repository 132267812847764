import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class PageNotFound extends Component {
    render() {
        return (
            <div>
                 <div className="pagenotfound">

<div className="pagenotfount_container">
    <h1 className="pagenotfound_content">404 || The Page Not Found</h1>
    <Link href="/"><span className="pagenotfound_link"> Go to Back home</span></Link>
</div>
</div>
            </div>
        );
    }
}

export default PageNotFound;