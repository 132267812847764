import React, { Component } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import '../styles/service.css'
import Mobile from '../assets/images/services/mobile.svg';
import Technology from '../assets/images/services/tech.svg';
import Web from '../assets/images/services/web.svg';
import Ui from '../assets/images/services/ui.svg';
import Bigdata from '../assets/images/services/bigdata.svg';
import Quality from '../assets/images/services/quality.svg';
import MVP from '../assets/images/services/mvp.svg';
import Digital from '../assets/images/services/digital.svg';
import Enterprice from '../assets/images/services/enterprice.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from "react-helmet";
import Favicon from '../favicon.ico';
import LMLogo from '../Localisem-Logo.svg';
import Layout from '../components/layout_sub';
import Fade from 'react-reveal/Fade';
import ServiceIns from '../components/serviceIndustry';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { NavHashLink } from 'react-router-hash-link';
class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);}
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <title>Services | Mobile App, Web App and BigData Solutions in Tirupattur, Tamil Nadu</title>
                    <meta name="description" content="We do all our web and mobile services are more responsive in all the devices. Automate your business to reduce chaos. for more insights Please Contact Us." />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="keywords" content="web app developments,iOS app developments,app developments,software developments,mac app developments localisem,localism" />
                    <link rel="canonical" href="https://www.localisem.com/services" />
                    <meta property="og:url" content="https://www.localisem.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Services | Mobile App , Web App and BigData Solutions in Tirupattur, Tamil Nadu" />
                    <meta property="og:description" content="We do all our web and mobile services are more responsive in all the devices. Automate your business to reduce chaos. for more insights Please Contact Us" />
                    <meta property="og:site_name" content="https://www.localisem.com" />
                    <link rel="shortcut icon" sizes="16x16" href={Favicon} />
                    <link rel="shortcut icon" sizes="24x24" href={Favicon} />
                    <link rel="shortcut icon" sizes="32x32" href={Favicon} />
                    <link rel="shortcut icon" sizes="64x64" href={Favicon} />
                    <link rel="icon" href={Favicon} />
                    <link rel="apple-touch-icon" href={LMLogo} />
                    <link rel="apple-touch-icon-precomposed" href={LMLogo} />
                </Helmet>
              <Layout>
                    <div className="services">
               
                        <div className="service_container">
                        <Container fluid>

                            <div id="service" className="service_sec">


                                <h1 className="header-title">Services</h1>
                                <h2 className="sub-title">We Do 9 Services Best In 9 Industries</h2>
                                <div className="service-wrap">

                                    <Row className="service-rows">

                                        <Col lg={5} className="my-auto">

                                            <h1 className="header-sub-title">Mobile App Development</h1>
                                            <p className="content ">We bring your dream business alive on all mobile devices like iOS & Android to impress your clients. We provide custom development apps for any kind of business to amaze your customer, Apps can be built in Native iOS, Native android or cross platform apps using platform specific languages.</p>
                                        </Col>
                                        <Col lg={6} lg={{ order: 5 }} className="my-auto">

                                            <Fade right>
                                                <Image src={Mobile} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} lg={{ order: 5 }} className="my-auto">

                                            <h1 className="header-sub-title">Web Application Development </h1>
                                            <p className="content">We provide attractive and impressive, responsive websites for your business identity. We build static and custom responsive web applications using most robust technologies for any businesses like Startups, midsize and enterprise companies.</p>

                                        </Col>
                                        <Col lg={6} className="my-auto" >
                                            <Fade left>
                                                <Image src={Web} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} className="my-auto">

                                            <h1 className="header-sub-title">UX/UI Design </h1>
                                            <p className="content">We build all our products using industry best UI/UX practices to make your product amazing to impress your team and clients, and we do consulting service as well.</p>

                                        </Col>
                                        <Col lg={6} lg={{ order: 5 }} className="my-auto">
                                            <Fade right>
                                                <Image src={Ui} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} lg={{ order: 5 }} className="my-auto">
                                            <h1 className="header-sub-title">Technology Consulting</h1>
                                            <p className="content">Our technology consulting team will provide the industry’s best practice solution to companies to improve their software architecture, automat the legacy flows and improve operations by optimizing your software portfolio. </p>

                                        </Col>

                                        <Col lg={6} className="my-auto">
                                            <Fade left>
                                                <Image src={Technology} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} className="my-auto">

                                            <h1 className="header-sub-title">Digital Transformations</h1>
                                            <p className="content">We provide turnkey solutions to digitally enable any business from manual to automated process within budget and providing 360-degree customer views</p>

                                        </Col>
                                        <Col lg={6} lg={{ order: 5 }} className="my-auto" >
                                            <Fade right>
                                                <Image src={Digital} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} lg={{ order: 5 }} className="my-auto">
                                            <h1 className="header-sub-title">MVP Development </h1>
                                            <p className="content">We provide MVP development service for any startups to go market sooner to collect maximum value from the market with least effort. This will help for startups to make the best decisions for their upcoming products.</p>

                                        </Col>
                                        <Col lg={6} className="my-auto">
                                            <Fade left>
                                                <Image src={MVP} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} className="my-auto">

                                            <h1 className="header-sub-title">Bigdata Solutions</h1>
                                            <p className="content">We provide custom flows to ingest the data from various sources into data lake and custom automated flows to transform the data from raw zone to conformed zone using industry top Hadoop and cloud technologies. </p>

                                        </Col>
                                        <Col lg={6} lg={{ order: 5 }} className="my-auto">
                                            <Fade right>
                                                <Image src={Bigdata} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">


                                        <Col lg={5} lg={{ order: 5 }} className="my-auto">

                                            <h1 className="header-sub-title">Enterprise Application Development</h1>
                                            <p className="content">We provide custom end to end service to build the enterprise application development from scratch or redevelop the legacy applications using micro service to boost the best performance. </p>

                                        </Col>
                                        <Col lg={5} className="my-auto">
                                            <Fade left>
                                                <Image src={Enterprice} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                    <Row className="service-rows">

                                        <Col lg={5} className="my-auto">

                                            <h1 className="header-sub-title">Quality Assurance & Testing</h1>
                                            <p className="content">We provide quality assurance and testing services to establish concrete control over your product, monitor every development stage and give you accurate product quality information.</p>

                                        </Col>
                                        <Col lg={6} lg={{ order: 5 }} className="my-auto">
                                            <Fade right>
                                                <Image src={Quality} alt="pic" />
                                            </Fade>
                                        </Col>
                                    </Row>

                                </div>

                           </div>

                        </Container>
                        </div>
                        <div className="serviceins">
                            <h1 className="header-title">Our Industry Expertise</h1>
                            <ServiceIns />
                        </div>
                        <div className="service_offers">

                            <Container fluid>
                                <Row>
                                    <Col xl={12}><h1 className="service_offers_content text-center">We do all our web and mobile services are more responsive in all the devices. Automate your business to reduce chaos. for more insights Please  <NavHashLink to="/#contact" style={{ textDecoration: 'none', color: '#0D25AF', fontWeight: '600' }}> Contact Us</NavHashLink>.</h1></Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default Services;