import React, { Component } from "react";
import { Form, Container, Row, Col, Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import http from "../services/config/axios";
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      subject: "",
      description: "",
      phone: "",
      body: "",
      message: "",
      fields: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  };
  handleChangePhoneNumber = (val) => {
    let fields = this.state.fields;
    fields["phone"] = val;
    this.setState({
      fields,
    });
  };

  addContactUs(data) {
    return http.post(`/login/contactus`, data);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      let phone = {};
      fields["name"] = "";
      fields["email"] = "";
      fields["phone"] = "";
      fields["subject"] = "";
      fields["body"] = "";

      this.setState({ fields: fields });
      let data = {
        id: null,
        profileId: "6312ae3aa6337d2localisem",
        profileDisplayName: "www.localisem.com",
        profilePictureUrl:
          "https://mf.sharebites.org/users/localisemlogo/6312ae3aa6337d2localisem.jpg",
        reasonToContactUs: "General inquiry",
        status: "Open",
        subject: "Message from www.localisem.com",
        message: `${this.state.fields.name},${this.state.fields.email},${this.state.fields.phone},${this.state.fields.subject},${this.state.fields.body}`,
        urls: null,
        response: null,
        createTs: new Date().toISOString(),
        updateTs: new Date().toISOString(),
      };

      http
        .post(`/login/contactus`, data)
        .then(
          (response) => (
            Swal.fire({
              title: "Thank you for submitting your request!",
              text: "Email to contact@localisem.com for immediate response",
              icon: "success",
            }),
            response
          )
        )
        .catch(
          (error) => (
            Swal.fire({
              titleText: "Error!",
              icon: "error",
            }),
            error.message
          )
        );
      this.clearForm();
      /*  const scriptURL = */
      /* "https://script.google.com/macros/s/AKfycbw8isDbzliEFymfeIQqnr13WQDbojcuAG3bH4Euxh2PIpvc5i5np3Iqwg/exec"; */
      /* "https://script.google.com/macros/s/AKfycbwVrJBePMfovt7BPvVzNy6OfbdjVc3wUKsfoBv7K_O0IcM0NqZ61sf4WNR1T5tdLN4H/exec";
      const form = document.forms["submit-to-google-sheet"]; */

      /*  axios
        .post(scriptURL, new FormData(form))
        .then(
          (response) => (
            Swal.fire({
              title: "Thank you for submitting your request!",
              text: "We will Connect you soon!",
              icon: "success",
            }),
            response
          )
        )
        .catch(
          (error) => (
            Swal.fire({
              titleText: "Error!",
              icon: "error",
            }),
            error.message
          )
        );  */

      /*  fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then(
          (response) => (
            Swal.fire({
              title: "Thank you for submitting your request!",
              text: "We will Connect you soon!",
              icon: "success",
            }),
            response
          )
        )
        .catch(
          (error) => (
            Swal.fire({
              titleText: "Error!",
              icon: "error",
            }),
            error.message
          )
        ); */
    }
  };

  clearForm() {
    document.getElementById("myform").reset();
    this.setState({
      email: "",
      name: "",
      phone: "1",
      subject: "",
      message: "",
      body: "",
    });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please enter your name.";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    /* if (!fields["phone"]) {
          formIsValid = false;
          errors["phone"] = "*Please enter your phone number.";
        }
    
       if (typeof fields["phone"] !== "undefined") {
          if (!fields["phone"].match(/^[0-9]{15}$/)) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid phone number.";
          }
        }*/

    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "*Please enter your subject.";
    }

    if (!fields["body"]) {
      formIsValid = false;
      errors["body"] = "*Please enter your project description.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    return (
      <div>
        <div>
          <div className="contact-col">
            <form id="myform">
              <Form.Control
                size="lg"
                type="email"
                placeholder="Your Email Id"
                name="email"
                value={this.state.fields.email}
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.email}</div>
              <br />
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Your name"
                    name="name"
                    value={this.state.fields.name}
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{this.state.errors.name}</div>
                </Form.Group>
                <Form.Group as={Col}>
                  <PhoneInput
                    inputStyle={{ height: "48px", width: "100%" }}
                    value={this.state.phone}
                    onChange={(phone) => this.handleChangePhoneNumber(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    country={"us"}
                    countryCodeEditable={false}
                    autoFormat={true}
                    enableSearch={true}
                  />
                  <div className="errorMsg">{this.state.errors.phone}</div>
                </Form.Group>
              </Form.Row>

              <Form.Control
                size="lg"
                type="text"
                placeholder="Subject"
                name="subject"
                value={this.state.fields.subject}
                onChange={this.handleChange}
              />

              <div className="errorMsg">{this.state.errors.subject}</div>
              <br />
              <Form.Control
                size="lg"
                as="textarea"
                placeholder="Project Description"
                rows={5}
                name="body"
                value={this.state.fields.body}
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.body}</div>
              <br />
              <button
                className="contactus-button"
                onClick={this.handleSubmit}
                type="submit"
                size="lg"
              >
                <FontAwesomeIcon icon={["fas", "envelope"]} /> SUBMIT{" "}
                <span className="pulse_circle pulse orange"></span>
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
