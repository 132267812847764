import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import NativeIOS from '../assets/images/Technologies/Native iOS.svg';
import NativeAndroid from '../assets/images/Technologies/Native Android.svg';
import WebTechnologies from '../assets/images/Technologies/Web Technologies.svg';
import ServerTechnologies from '../assets/images/Technologies/Server Technologies.svg';
import BigdataTechnologies from '../assets/images/Technologies/BigData Technologies.svg';
import CloudTechnologies from '../assets/images/Technologies/Cloud.svg';
import DesignTechnologies from '../assets/images/Technologies/Design.svg';
import TestingTechnologies from '../assets/images/Technologies/Testing.svg';
import DatabaseTechnologies from '../assets/images/Technologies/Data Base.svg';
import { Link } from 'react-router-dom';
import '../styles/owl-carousel.css';
import { NavHashLink } from 'react-router-hash-link';
const options = {
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        800: {
            items: 1,
        },
        900: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};

class TechnologiesTypes extends Component {
    render() {
        return (
            <div>
                <div className="technology-carousel ">
                    <Container fluid >
                        <div className="tech_carousel_container">
                            <h1 className="header-title">Technologies We Use</h1>
                            <p className="content tech_content text-center"> We are using cutting-edge technologies to build your product to work in every situations, here we have highlighted few of the technologies for your reference, for more insights about technologies, Please <NavHashLink to="/#contact" style={{ color: '#0d25af', fontWeight: '600', textDecoration: 'none' }}>Contact Us</NavHashLink>.</p>

                            <Row className="">
                                <Col xl={12}>
                                    <OwlCarousel
                                        className="owl-theme"
                                        refreshClass='owl-refresh'
                                        autoHeightClass='owl-height'
                                        navContainerClass='owl-nav'
                                        center={true}
                                        nav={true}
                                        responsiveClass={true}
                                        loop={true}
                                        items={2}
                                        margin={20}
                                        mergeFit={true}
                                        mouseDrag={true}
                                        touchDrag={true}
                                        pullDrag={true}
                                        autoplay={true}
                                        autoplaySpeed={1000}
                                        navSpeed={true}
                                        video={true}
                                        autoplayTimeout={2500}
                                        autoplayHoverPause={true}
                                        lazyLoad={true}
                                        onLoadedLazy={true}
                                        dragEndSpeed={1000}
                                        responsiveClass={true}
                                        responsive
                                        slideBy={1}
                                        {...options}
                                    >
                                        <div className="technology-item">
                                            <Image src={NativeIOS} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={NativeAndroid} className="techcarousel-image" />
                                        </div>

                                        <div className="technology-item">
                                            <Image src={WebTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={ServerTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={BigdataTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={CloudTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={DesignTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={TestingTechnologies} className="techcarousel-image" />
                                        </div>
                                        <div className="technology-item">
                                            <Image src={DatabaseTechnologies} className="techcarousel-image" />
                                        </div>
                                    </OwlCarousel>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default TechnologiesTypes;