import React, { Component } from 'react';
import connect from '../assets/images/connnect.svg'
import confident from '../assets/images/confident.svg'
import goal from '../assets/images/goal.svg';
import price from '../assets/images/price.svg';
import build from '../assets/images/buildexp.svg';
import sprint from '../assets/images/sprint.svg';
import develop from '../assets/images/develop.svg';
import live from '../assets/images/live.svg';
import support from '../assets/images/support.svg';
import { Row, Col, Image, Container } from 'react-bootstrap';
class HowweWork extends Component {
    render() {
        return (
            <div>
                <div className="process">
                    <Container fluid>
                        <div className="process-section" id="howwework">
                            <Row>
                                <Col xl={12}>
                                    <div className="process_sec">
                                        <div className="process-header">
                                            <h1 className="header-title" >HOW WE WORK</h1>
                                            <h2 className="sub-title">Easy and Transparency Process</h2>
                                        </div>
                                        <div>
                                            <Row className="process-row">

                                                <Col lg={5} className="my-auto">

                                                    <h1 className="header-sub-title">1. Let’s Socialize</h1>
                                                    <p className="content">Let’s have a practical and mindful conversation about the possibilities and strategies we can implement in our project. Our dedicated business team ll make you comfortable throughout this journey and provide the final product which amazes you and your audiences as well.</p>

                                                </Col>
                                                <Col lg={6} lg={{ order: 5 }} className="poster-container">

                                                    <Image className="process-poster right" src={connect} alt="pic" />

                                                </Col>
                                            </Row>
                                            <Row className="process-row">


                                                <Col lg={5} lg={{ order: 5 }} className="my-auto">
                                                    <h1 className="header-sub-title">2. Establishing Confidence</h1>
                                                    <p className="content">Mutual trust is the foundation of every business. Once you shared your unique ideas with us, it will be protected confidentially under the cyber security best practices and internally as well. Client’s trust is our primary value.</p>
                                                </Col>

                                                <Col lg={6} className="poster-container">
                                                    <Image className="process-poster left" src={confident} alt="pic" /> </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} className="my-auto">
                                                    <h1 className="header-sub-title">3. Understand Your Goals</h1>
                                                    <p className="content">Asking many questions ll bring out robust solutions. We may ask multiple questions to understand your goal, and to give you a better assistance. Realistic Strategies can be discussed during this session.</p>

                                                </Col>
                                                <Col lg={6} lg={{ order: 5 }} className="poster-container">
                                                    <Image className="process-poster right" src={goal} alt="pic" />
                                                </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} lg={{ order: 5 }} className="my-auto">
                                                    <h1 className="header-sub-title">4. Price </h1>
                                                    <p className="content">The better we know what you want to achieve, the more detailed quote you will get. A single price broken down into modules and milestones is what we agree at the beginning. Then, we divide your payments into instalments directly linked to the progress of our work.</p>

                                                </Col>
                                                <Col lg={6} className="poster-container">
                                                    <Image className="process-poster left" src={price} alt="pic" /> </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} className="my-auto">

                                                    <h1 className="header-sub-title">5. Building The Experience</h1>
                                                    <p className="content">Your project will be as good as the experience it offers. Our UX designers will create UX map from scratch, then prepare the wireframes and finally a fully working mockup.</p>

                                                </Col>
                                                <Col lg={6} lg={{ order: 5 }} className="poster-container">

                                                    <Image className="process-poster right" src={build} alt="pic" />

                                                </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} lg={{ order: 5 }} className="my-auto">

                                                    <h1 className="header-sub-title">6. Sprint Plan</h1>
                                                    <p className="content">A development process is usually divided into two weeks of sprints. Each sprint covers the scope of work essential for shipment of a finished part of app functionality. The product owner sees the immediate result of completed work, tests it, and makes changes to the project if needed. </p>

                                                </Col>
                                                <Col lg={6} className="poster-container">

                                                    <Image className="process-poster left" src={sprint} alt="pic" />
                                                </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} className="my-auto">

                                                    <h1 className="header-sub-title">7. Development & Testing</h1>
                                                    <p className="content">We will be using well known and tested technologies to do digital transformation from prototype to your final product. All this bearing in mind top of industry standards. </p>

                                                </Col>
                                                <Col lg={6} lg={{ order: 5 }} className="poster-container">

                                                    <Image className="process-poster right" src={develop} alt="pic" />

                                                </Col>
                                            </Row>

                                            <Row className="process-row">

                                                <Col lg={5} lg={{ order: 5 }} className="my-auto">

                                                    <h1 className="header-sub-title">8. Go Live</h1>
                                                    <p className="content">When we are all happy with final product, the big day has come and the product is ready to go live. We will ensure a smooth start and fulfilling all requirements and making sure all works as expected.</p>

                                                </Col>
                                                <Col lg={6} className="poster-container">

                                                    <Image className="process-poster left" src={live} alt="pic" />

                                                </Col>
                                            </Row>


                                            <Row className="process-row">

                                                <Col lg={5} className="my-auto">

                                                    <h1 className="header-sub-title">9. Fix & Support</h1>
                                                    <p className="content">Here you may think our work is done but your project may need maintenance and upgrades from time to time. No worries – you always get 3 months guarantee support for free of cost, after that we can continue working for you using the SLA mechanism</p>

                                                </Col>
                                                <Col lg={6} lg={{ order: 5 }} className="poster-container">
                                                    <Image className="process-poster  right" src={support} alt="pic" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>
            </div>
        );
    }
}

export default HowweWork;