import Footer from "./footer";
import Header from "./header_sub";
export default function Layout({children}){
    return(
        <div>
           <Header />
            {children}
            <Footer />
        </div>
    )
}