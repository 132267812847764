import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Link } from 'react-router-dom';

class Footer extends Component {
    whatsapp = (number) => {
        number = 917339028235
        var url = 'https://api.whatsapp.com/send?phone=' + number

        window.open(url)
    }
    render() {
        return (
            <div>

                <div className="footer">
                    <Container fluid>
                        <div className="footer-address">
                            <Row className="">
                                <Col lg={4} className="mb-4"><div>
                                    <li className="footer_link"><a href="/" style={{ color: '#ffffff', textDecoration: 'none' }}> Home</a></li>
                                    <li className="footer_link"><NavLink href="" to="/company" style={{ color: '#ffffff', textDecoration: 'none' }}>Company</NavLink></li>
                                    <li className="footer_link"><NavLink href="" to="/service" smooth={true} style={{ color: '#ffffff', textDecoration: 'none' }}>Services</NavLink></li>
                                    <li className="footer_link"><NavLink href="" to="/technology" style={{ color: '#ffffff', textDecoration: 'none' }}>Technologies</NavLink></li>
                                    <li className="footer_link"><a href="/company/#aboutus" style={{ textDecoration: 'none', color: 'white' }} >About Us</a></li>
                                    <li className="footer_link"><a href="/#price" style={{ color: '#ffffff', textDecoration: 'none' }}>Prices</a></li>
                                    <li className="footer_link"><a href="/#contact" style={{ color: '#ffffff', textDecoration: 'none' }}>Contact Us</a></li>
                                </div>
                                </Col>
                                <Col lg={4} className="mb-4">


                                    <li className="footer_address"><span style={{ fontWeight: 'bold' }}>INDIA</span>, 3/5 Merkathiyanoor, Matrapalli,</li>
                                    <li className="footer_address">Tirupattur Town & DT,</li>
                                    <li className="footer_address mb-4">Tamil Nadu, 635 601.</li>


                                    <li className="footer_address"><span style={{ fontWeight: 'bold' }}>USA</span>, 7310 Bromyard Terrace,</li>
                                    <li className="footer_address">Cumming, Atlanta,</li>
                                    <li className="footer_address"> GA, 30040. </li>
                                </Col>

                                <Col lg={4} className="mb-4">

                                    <li className="footer_media " style={{ fontSize: '20px', fontFamily: 'Roboto' }}>contact@localisem.com</li>

                                    {/* <button onClick={this.whatsapp} className="footer_whatsapp_button" ><FontAwesomeIcon icon={['fab', 'whatsapp']} /> Whatsapp</button> */}
                                </Col>

                            </Row>
                        </div>
                    </Container>


                    <div className="footer-copyright d-flex justify-content-center align-items-center">
                        <Container fluid>
                            <Row className="justify-content-md-center ">
                                <Col className="my-auto ">
                                <div className='text-center footer_copyright_content pt-2 pb-2' style={{ backgroundColor: '#0000001a' }}> <span className='fm_SSP fc_blackgray sbw_content text-center'>
                                    Localisem Technologies Private Limited © Copyright {new Date().getFullYear()} | All Rights Reserved</span></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default Footer;