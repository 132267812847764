import React, { Component } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';
import Banking from '../assets/images/services/banking.svg';
import Retail from '../assets/images/services/retail.svg';
import PersonalCare from '../assets/images/services/personalcare.svg';
import ECommerce from '../assets/images/services/ecommerce.svg';
import HealthCare from '../assets/images/services/healthcare.svg';
import Education from '../assets/images/services/education.svg';
import Travel from '../assets/images/services/travel.svg';
import Media from '../assets/images/services/media.svg';
import Logistics from '../assets/images/services/logistics.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../styles/owl-carousel.css';
const options = {
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 2,
  
        }
    },
  };
class ServiceIndustries extends Component {
    render() {
        return (
            <div>
                <div className="industry">
                    <Container fluid>
                        <div className="industry-container">

                            <Row>
                                <Col xl={12}>
                                    <OwlCarousel
                                        className="owl-theme"
                                        refreshClass='owl-refresh'
                                        autoHeightClass='owl-height'
                                        navContainerClass='owl-nav'
                                        responsiveClass={true}
                                        loop={true}
                                        items={2}
                                        margin={20}
                                        mergeFit={true}
                                        nav={true}
                                        center={true}
                                        mouseDrag={true}
                                        touchDrag={true}
                                        pullDrag={true}
                                        autoplay={true}
                                        autoplaySpeed={1000}
                                        navSpeed={true}
                                        video={true}
                                        autoplayTimeout={2000}
                                        autoplayHoverPause={true}
                                        dots={true}
                                        lazyLoad={true}
                                        onLoadedLazy={true}
                                        dragEndSpeed={1000}
                                        responsiveClass={true}
                                        responsive
                                        slideBy={1}
                                        {...options}
                                    >

                                        <div className="item">
                                            <Image src={Banking} alt="pic" />
                                            <h1 className="serviceins-title">Banking</h1>
                                            <p className="serviceins-content">We build custom software to any finance startups to automate their process and make their flows easy to communicate with customers to make them feel happy. We protect every bit of customer data with encryption softwares. </p></div>

                                        <div className="item">
                                            <Image src={Retail} alt="pic" />
                                            <h1 className="serviceins-title">Retail</h1>
                                            <p className="serviceins-content">We build custom software for retailers to make their process automate and custom tools like CRM, Inventory management, Retail accounting, Supply Chain and etc.</p></div>

                                        <div className="item">
                                            <Image src={PersonalCare} alt="pic" />
                                            <h1 className="serviceins-title">PersonalCare</h1>
                                            <p className="serviceins-content">We build custom software for startups and SMBs to connect their customers in digital way to make the customers feel happy to retain them, analyze the customer behavior and etc. Some of the personal care service is Lawn maintenance, House Care, SPA, Personal assistance and etc.</p></div>

                                        <div className="item">
                                            <Image src={ECommerce} alt="pic" />
                                            <h1 className="serviceins-title">E-Commerce</h1>
                                            <p className="serviceins-content">We build custom apps & website for startups and SMBs to sell their product in online and other tools to automate their process like CRM, Admin Dashboard, Vendor Dashboard and etc.</p></div>

                                        <div className="item">
                                            <Image src={HealthCare} alt="pic" />
                                            <h1 className="serviceins-title" >HealthCare</h1>
                                            <p className="serviceins-content">We build custom software to all healthcare services (hospital, clinic, pharmacy and fitness) to optimized their workflows, online appointment, paper less communication between various users like hospitals, pharmacy, patient and etc. We protect every bit of health information (PHI) with health care protocols like HIPAA, ISO and etc.</p>
                                        </div>
                                        <div className="item">
                                            <Image src={Education} alt="pic" />
                                            <h1 className="serviceins-title">Education</h1>
                                            <p className="serviceins-content">We provide a platform and custom software to education institute for online classes and other tools like admin process and etc.  </p>
                                        </div>

                                        <div className="item">
                                            <Image src={Travel} alt="pic" />
                                            <h1 className="serviceins-title">Travel</h1>
                                            <p className="serviceins-content">We build custom apps, websites and custom software (HMS & TMS) to startups & SMBs to meet high customer service expectations. </p>
                                        </div>
                                        <div className="item">
                                            <Image src={Media} alt="pic" />
                                            <h1 className="serviceins-title">Media</h1>
                                            <p className="serviceins-content">We build custom platform for media industry to build their own over the top (OTT) and video on demand (VOD) platform to launch their content.</p>
                                        </div>
                                        <div className="item">
                                            <Image src={Logistics} alt="pic" />
                                            <h1 className="serviceins-title">Logistics</h1>
                                            <p className="serviceins-content">We provide custom software to logistics company to lower operational costs, optimize workflows with mobile applications for their warehouse, supply chains, transportations and manage logistics assets with barcode.</p>
                                        </div>


                                    </OwlCarousel>
                                </Col></Row>

                        </div>
                    </Container>
                </div>
            </div>

        );
    }
}

export default ServiceIndustries;