import React, { Component } from 'react';
import { Row, Col, Container, Image, Form } from 'react-bootstrap';
import partner from '../assets/images/partner.svg';
import ContactForm from './contactform';
class Partner extends Component {
    render() {
        return (
            <div>
                <div className="partner">
                    <Container fluid>
                        <div className="partner_section">
                            <Row>
                                <Col xl={12}>
                                    <div className="partner_sec">
                                        <div className="partner-header text-center" ><h1 className="header-title">PARTNER</h1></div>
                                        <Row className="partner-rows">
                                            <Col lg={6} lg={{ order: 5 }} className="my-auto"><Image src={partner} alt="pic" /></Col>
                                            <Col lg={5} className="my-auto"><h1 className="header-sub-title">Refer a business to Localisem</h1>
                                                <p className="content">We believe that referrals are the greatest form of flattery. If you know someone who is worried about any aspect of their business technology, do them a favor and put them in touch with us.</p>
                                            </Col>
                                        </Row>

                                        <Row className="partner-rows">

                                            <Col lg={6} className="my-auto">
                                                <h1 className="partner-subtitle">Three step process to become partner</h1>
                                                <p className="content" style={{ lineHeight: '30px' }}>1. Enter your info in the form or email <span style={{ color: 'blue' }}>contact@localisem.com</span></p>
                                                <p className="content" style={{ lineHeight: '30px' }}>2. Refer Customer</p>
                                                <p className="content" style={{ lineHeight: '30px' }}>3. Earn revenue share</p>
                                            </Col>

                                            <Col lg={6} className="contactform">
                                            <ContactForm />
                                            </Col>
                                            <Col md={12}><p className="content text-center">By recommending partners, associates, or professional contacts, you can help them enjoy worry-free IT and Its great idea to generate the passive revenue for yourself. We look forward to hearing from you!</p></Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Container>
                </div>
            </div>
        );
    }
}

export default Partner;