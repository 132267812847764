import React, { Component } from 'react';
import { Navbar, Nav, Button, NavDropdown, Modal, Image, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/Localisem-Logo-05-02-2021.svg'
import { NavHashLink } from 'react-router-hash-link';

library.add(fab, fas, faCheckSquare, faCoffee)
class Header extends Component {
    state = {
        navBackground: "#ffffff",
        zIndex: "0",
        color: '#0d25af',
        navExpanded: false,
        show: false,
        dropdownshow: false
    }





    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    }
    dropdownExpanded = () => {
        this.setState({ dropdownshow: true });
    }
    setNavClose = () => {

        this.setState({ navExpanded: false });
        this.setState({ dropdownshow: false });
    }
    handle = () => {
        this.setState({ show: !this.state.show });
        this.setState({ navExpanded: false });
    }
    render() {
        return (
            <div>
                <div>

                    <Navbar collapseOnSelect className="navbar_row" expand="lg" id="navbar" fixed="top" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
                        <Container fluid className="navbar_container">
                            <Navbar.Brand href="/" className="brand"><Image src={logo} className="logoimage" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ outline: 'none', borderRadius: '100px' }} className="togglebutton">{this.state.navExpanded ? <FontAwesomeIcon className="togglefont" style={{ color: 'red', width: '1em', height: '1em' }} icon={['fas', 'times']} /> : <FontAwesomeIcon className="togglefont" style={{ color: '#0d25af', width: '1em', height: '1em' }} icon={['fas', 'stream']} />}</Navbar.Toggle>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ml-auto navbar-items">
                                    <NavHashLink activeClassName="selected" onClick={this.setNavClose} to="/#home" className="link link_menu">Home</NavHashLink>
                                    <div className="dropdown" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
                                        <NavLink activeClassName="active" activeStyle={{ color: '#00AC52' }} to="/company" onClick={this.setNavClose} className="link">
                                            Company</NavLink>
                                        <span> <FontAwesomeIcon className="subnavbtn" icon={['fa', 'caret-down']} /></span>
                                        <div className="dropdown_items" onToggle={this.dropdownExpanded}>
                                            <NavHashLink to="/company#how" onClick={this.setNavClose} className="dropdown_item">How We Work</NavHashLink>
                                            <NavHashLink to="/company#why" onClick={this.setNavClose} className="dropdown_item">Why Choose Us</NavHashLink>
                                            <NavHashLink to="/company#aboutus" onClick={this.setNavClose} className="dropdown_item">About Us</NavHashLink>
                                            <NavHashLink to="/company#partner" onClick={this.setNavClose} className="dropdown_item">Partner</NavHashLink>
                                            <NavHashLink to="/company#faq" onClick={this.setNavClose} className="dropdown_item">FAQ</NavHashLink>
                                            <a onClick={this.handle}  className="dropdown_item">Careers</a>
                                        </div>

                                    </div>
                                    <NavLink activeClassName="active" activeStyle={{ color: '#00AC52' }} onClick={this.setNavClose} to="/service" className="link link_menu">Services</NavLink>
                                    <NavLink activeClassName="active" activeStyle={{ color: '#00AC52' }} onClick={this.setNavClose} to="/technology" className="link link_menu">Technologies</NavLink>
                                    <NavHashLink activeClassName="selected" activeStyle={{ color: '#00AC52' }} onClick={this.setNavClose} to="/#price" className="link link_menu">Pricing</NavHashLink>
                                    <NavHashLink activeClassName="selected" activeStyle={{ color: '#00AC52' }} onClick={this.setNavClose} to="/#contact" className="link link_menu">Contact Us</NavHashLink>

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                </div>
                <Modal
                    show={this.state.show}
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={this.handle}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><h2 className="career-title">Careers</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="content career_content">Thanks for reaching to us. Kindly share your details to <span style={{ color: 'blue' }}>contact@localisem.com </span> and our team will respond to you</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button block variant="primary" onClick={this.handle}>
                            Close
                                 </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Header;