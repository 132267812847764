import React, { Component } from 'react';
import { Row, Col, Container, Image, Button, Card, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../styles/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import Gotidea from '../assets/images/gotidea.svg';
import experience from '../assets/images/header-content1.svg';
import EnterpriseApp from '../assets/images/enterpriseappdevelopment.svg';
import MobileApp from '../assets/images/mobileappdevelopment.svg';
import MVP from '../assets/images/mvpdevelopment.svg';
import Delivery from '../assets/images/delivery.svg';
import { Helmet } from "react-helmet";
import BigdataPipline from '../assets/images/Usecase/Bigdata Ingestion Pipeline.svg';
import BigdataTransformation from '../assets/images/Usecase/Bigdata Transformation.svg';
import EducationSystem from '../assets/images/Usecase/Education Management System.svg';
import LogisticsSystems from '../assets/images/Usecase/Logistics Management Systems.svg';
import OnlineAppointment from '../assets/images/Usecase/Online Appointment System.svg';
import OnlineOrder from '../assets/images/Usecase/Online Order Systems.svg';
import RideApp from '../assets/images/Usecase/Ride App system.svg';
import SocialMedia from '../assets/images/Usecase/Social Media Apps.svg';
import CloudComputing from '../assets/images/Usecase/Bigdata_Flow.svg';
import $ from 'jquery';
import anime from 'animejs';
import Favicon from '../favicon.ico';
import LMLogo from '../Localisem-Logo.svg';
import Layout from '../components/layout';
import WhyLM from '../components/whylmcarousel';
import ContactForm from '../components/contactform';
import sample from '../assets/images/callme.svg';
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, animateScroll as scroll } from 'react-scroll'
import { NavHashLink } from 'react-router-hash-link';
class Home extends Component {


    componentDidMount() {
        window.scrollTo(0, 0);
        $('.round').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.arrow').toggleClass('bounceAlpha');
        });

        var textWrapper = document.querySelector('.banner_header_title .letter');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({ loop: true })
            .add({
                targets: '.banner_header_title .letter',
                translateX: [30, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1200,
                delay: (el, i) => 500 + 30 * i
            }).add({
                targets: '.banner_header_title',
                translateX: [0, -20],
                opacity: [1, 0],
                easing: "easeInExpo",
                duration: 1100,
                delay: (el, i) => 500 + 30 * i
            });

        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/5fb3b90c3e20f61525e43836/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
    }
    whatsapp = (number) => {
        number = 917339028235
        var url = 'https://api.whatsapp.com/send?phone=' + number

        window.open(url)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <title itemProp="name" lang="en">Localisem - Digital Solutions To Amaze Your Team</title>
                    <meta name="description" content="𝗟𝗼𝗰𝗮𝗹𝗶𝘀𝗲𝗺 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗲𝘀 fervently craft attractive and impressive software solutions like Website development, Mobile App Development, BigData Development etc to increase your brand growth globally and digitally." />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="keywords" content="web app developments,iOS app developments,app developments,software developments,mac app developments localisem,localism" />
                    <link rel="canonical" href="https://www.localisem.com" />
                    <meta property="og:url" content="https://www.localisem.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Localisem - Digital Solutions To Amaze Your Team" />
                    <meta property="og:description" content="𝗟𝗼𝗰𝗮𝗹𝗶𝘀𝗲𝗺 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝗶𝗲𝘀 fervently craft attractive and impressive software solutions like Website development, Mobile App Development, BigData Development etc to increase your brand growth globally and digitally." />
                    <meta property="og:site_name" content="https://www.localisem.com" />
                    <link rel="shortcut icon" sizes="16x16" href={Favicon} />
                    <link rel="shortcut icon" sizes="24x24" href={Favicon} />
                    <link rel="shortcut icon" sizes="32x32" href={Favicon} />
                    <link rel="shortcut icon" sizes="64x64" href={Favicon} />
                    <link rel="icon" href={Favicon} />
                    <link rel="apple-touch-icon" href={LMLogo} />
                    <link rel="apple-touch-icon-precomposed" href={LMLogo} />
                </Helmet>
                <Layout>

                    <div className="home" >

                        <div className="hero_banner">
                            <Container fluid>
                                <div class="hero_banner_sec" id="home">
                                    <div className="hero_banner_container" >
                                        <Row className="banner_row">
                                            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                                                <div className="banner_contents">
                                                    <h1 className="banner_header_title slide-right"><span className="text_wrapper"><span className="letter">DIGITAL SOLUTIONS</span></span></h1>
                                                    <div className="banner_header_subtitles slide-left">
                                                        <ul className="list"><li className="banner_subtitle"> Localisem is a gateway to enhance your business globally and digitally</li>
                                                            <li className="banner_subtitle"> Build your dream business with our smart digital solutions</li>
                                                            <li className="banner_subtitle"> Amazing software to amaze your team</li>
                                                            <li className="banner_subtitle"> Cutting-Edge Technology</li></ul>
                                                    </div>
                                                </div>

                                                <div className="desktop"><NavHashLink to="/#contact"><button className="startButton" style={{ border: '0px' }}>Start <span className="pulse_circle pulse orange"></span></button></NavHashLink></div>

                                            </Col>
                                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>

                                                <Image className="banner_image pull-right" src={experience} />

                                                <div className="mobile"><center><NavHashLink to="/#contact"><button className="startButton" style={{ border: '0px' }}>Start <span className="pulse_circle pulse orange"></span></button></NavHashLink></center></div>
                                            </Col>

                                        </Row>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>

                    <div className="service-container">
                        <Container fluid>
                            <h1 className="header-title" >Services</h1>

                            <Row className="justify-content-md-center">
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center align-items-center">
                                    <div className="service_card">
                                        <div className="service-card-container p-2">
                                            <div className="service-card-box-inner">
                                                <div className="service-front-face d-flex align-items-center">
                                                    <Container>
                                                        <Row>
                                                            <Col md={12} className="d-flex justify-content-center">
                                                                <Image className="service-card-image" src={MobileApp} alt="Mobile_app" /></Col>
                                                            <Col md={12}><h2 className="service-header-h2">Mobile App Development</h2></Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                                <div className="service-back-face d-flex align-items-center">
                                                    <Container>
                                                        <Row>
                                                            <Col md={12} className="d-flex justify-content-center">
                                                                <h2 className="header-h2 ">Mobile App Development</h2>
                                                            </Col>
                                                            <Col md={12}>
                                                                <p className="content-p">This service is to build Android, iOS & Hybrid mobile application to enable any business into digital transformations.</p>
                                                            </Col></Row></Container></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center align-items-center">
                                    <div className="service_card">
                                        <div className="service-card-container p-2">
                                            <div className="service-card-box-inner" >
                                                <div className="service-front-face d-flex align-items-center">
                                                    <Container> <Row> <Col md={12} className="d-flex justify-content-center">
                                                        <Image className="service-card-image" src={MVP} alt="MVP_App" /></Col>
                                                        <Col md={12}><h2 className="service-header-h2">MVP Development</h2></Col></Row>
                                                    </Container>
                                                </div>
                                                <div className="service-back-face d-flex  align-items-center">
                                                    <Container> <Row> <Col md={12} className="d-flex justify-content-center">
                                                        <h2 className="header-h2">MVP Development</h2>
                                                    </Col>
                                                        <Col md={12}>
                                                            <p className="content-p">This service is designed for startups to reach the market sooner and to collect maximum value from the market with least effort.</p>
                                                        </Col></Row></Container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12} xs={12} className="d-flex justify-content-center align-items-center">
                                    <div className="service_card">
                                        <div className="service-card-container p-2">
                                            <div className="service-card-box-inner">
                                                <div className="service-front-face d-flex align-items-center">
                                                    <Container> <Row> <Col md={12} className="d-flex justify-content-center">
                                                        <Image className="service-card-image" src={EnterpriseApp} alt="EnterpriseApp" /></Col>
                                                        <Col md={12}><h2 className="service-header-h2">Enterprise Application Development</h2></Col></Row></Container>
                                                </div>
                                                <div className="service-back-face d-flex  align-items-center">
                                                    <Container> <Row> <Col md={12} className="d-flex justify-content-center">
                                                        <h2 className="header-h2">Enterprise Application Development</h2>
                                                    </Col>
                                                        <Col md={12}>
                                                            <p className="content-p"> This service includes Big Data ingestion & transformation flows, IoT, Micro service and other complex custom development applications.</p>
                                                        </Col></Row></Container>
                                                </div>
                                            </div></div>
                                    </div>
                                </Col>
                                <Col lg={12}><NavLink to="/service" className="readmore-link">Read More<span class="center-con"><span className="round"><span id="cta"><span className="arrow primera next "></span><span className="arrow arrow1 next "></span><span className="arrow arrow2 next "></span></span></span></span></NavLink></Col></Row>

                        </Container>
                    </div>
                    <div className="offers">

                        <Container fluid>
                            <Row>
                                <Col xl={12}><h1 className="offers-content text-center">We are privileged to digitally serve our farmers, fishermen, orphanages with no development cost.</h1></Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="ideaanddelivery">
                        <Container fluid>
                            <div className="ideacontainer">
                                <Row className="mt-4 " >

                                    <Col lg={6} md={6} sm={12} xs={12} className=" text-center poster-contents poster-1 my-auto ">

                                        <h1 className="poster-h1 text-center delivery_content">Our Bi-Weekly delivery plan offers no rush in cash burn. You can pay upon every milestones delivered.</h1>

                                    </Col>
                                    <Col lg={6} lg={{ order: 5 }} md={6} md={{ order: 5 }} sm={12} xs={12} className="my-auto">
                                        <center>  <Image src={Delivery} className="poster-image" /></center>

                                    </Col>
                                </Row>
                                <Row className=" mb-4 " >
                                    <Col lg={6} lg={{ order: 5 }} md={6} md={{ order: 5 }} sm={12} xs={12} className="text-center  poster-contents poster-2 my-auto d-flex justify-content-center align-items-center" >
                                        <div className="gotidea_group">
                                            <div className="gotidea_content"><h1 className="poster-h1 text-center">GOT AN IDEA?</h1></div>
                                            <div><p className="poster-content-p text-center">Get Compliment UX Design</p></div>

                                            <Row className="justify-content-md-center">
                                                {/* <Col className="p-2" xl={6} lg={6} md={12} sm={12} xs={12}><button onClick={this.whatsapp} className="gotidea_whatsapp_button" ><FontAwesomeIcon icon={['fab', 'whatsapp']} /> Whatsapp</button>
                                                </Col> */}
                                                <Col className="p-2 " xl={6} lg={6} md={12} sm={12} xs={12}>  <NavHashLink to="/#contact" className="gotidea_contact_link" style={{ textDecoration: 'none', color: '#0D25AF' }} ><button className="gotidea_contactus_button"> <FontAwesomeIcon icon={['fas', 'envelope']}/> Contact Us</button></NavHashLink>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="my-auto">
                                        <center>  <Image src={Gotidea} className="poster-image" /></center>
                                    </Col>

                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="offers">

                        <Container fluid>
                            <Row>
                                <Col xl={12}><h1 className="offers-content text-center">We proffer Free technical discussion to level up your business goals.</h1></Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="usecase">



                        <Container fluid>

                            <div className="usecaseContainer">
                                <h1 className="header-title" style={{ color: '#00ac52' }}>Crafted Usecases</h1>
                                <p className="content text-center mb-4">We have been in this industry for more than a decade and crafted lots of interesting applications, here we have highlighted few of our use cases for your reference, for more insights about use cases, Please <NavHashLink to="/#contact" style={{ textDecoration: 'none', color: '#0D25AF', fontWeight: '600' }}> Contact Us</NavHashLink></p>

                                <Row xl={3} lg={2} md={2} sm={1} xs={1} className="justify-content-md-center">


                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">

                                        <div className="usecase_container">

                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={BigdataPipline} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"> <h1 className="usecase_header">Bigdata Ingestion Pipeline</h1></Col></Row>
                                                </Container>
                                            </div>
                                            <div className="usecase_overlay text-center">
                                                <p className="usecase_overlay_contents text-center">
                                                    Bigdata ingestion pipeline is used to bring the large amount of raw data from various systems (RDBMS, API, Flat Files, Streaming and etc.) into Data Lake, pipeline includes predefined prechecks, post checks, data validations and orchestration to build a report.
                     </p>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={BigdataTransformation} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Bigdata Transformation</h1></Col>
                                                </Row></Container>
                                            </div>
                                            <div className="usecase_overlay text-center">
                                                <p className="usecase_overlay_contents ">
                                                    Bigdata transformation framework is used to transform the raw data into structured data that can be utilized with low latency for various usecases like analytics, data science, machine learning, etc. This framework also includes predefined prechecks, post checks, data validations and orchestration to build a report.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={CloudComputing} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4">  <h1 className="usecase_header text-center">Bigdata Flow Master</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay text-center">
                                                <p className="usecase_overlay_contents">
                                                    Bigdata flow master framework is used to support all necessary big data process like data ingestion, data transformations, data exports and are flexible to include custom pipeline without changing the codes in flow master. This is mainly built to help customers who does not have big data echo system knowledge. Anyone can use this framework as long as they know their data. This also comes with predefined checks and orchestration tool.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container" >
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={OnlineOrder} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Online Order System</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay usecase_left_container text-center d-flex justify-content-center align-items-center">
                                                <p className="usecase_overlay_contents">
                                                    Online order system is used to accept the online orders from customers for various business-like Restaurants, Super Markets, Home Goods, Pharmacies, Retails, Electronic Stores, etc. This system comes with separate admin portal and separate customer mobile app & websites.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={LogisticsSystems} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Logistics Management System</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay text-center ">
                                                <p className="usecase_overlay_contents">
                                                    Logistics management system is used to manage the logistics operations to automate the flows for order processing, inventory management and freight transformations. This comes with live freight tracking features for customers to get the instant status.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={OnlineAppointment} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Online Appointment System</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay usecase_right_container text-center">
                                                <p className="usecase_overlay_contents">
                                                    Online appointment system is used to accept / provide the flexibility for customers to schedule/request their appointment instantly. This system can be used for various business-like Hospitals, Salons, Personal advisors, etc.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={RideApp} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Ride App System</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay text-center ">
                                                <p className="usecase_overlay_contents">
                                                    Ride App is used for cab business to accept the request from customer for pickup and drop up. This system can be used for various sub sectors like Ride sharing, Delivery, Carpooling, Freight sharing and etc.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={EducationSystem} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"><h1 className="usecase_header text-center">Education Management System</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay usecase_bottom_container text-center ">
                                                <p className="usecase_overlay_contents">
                                                    Education management system is used to automate the entire cycle to eliminate increased workloads, which includes online application portal, administration portal, teacher portal and student portal.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mb-2 usecaseColumn d-flex justify-content-center align-items-center ">
                                        <div className="usecase_container">
                                            <div className="usecase_header_container d-flex justify-content-center align-items-center flex-wrap">
                                                <Container><Row><Col md={12} className="mb-4">
                                                    <Image src={SocialMedia} className="usecase_image" /></Col>
                                                    <Col md={12} className="mt-4"> <h1 className="usecase_header text-center">Social Media Apps</h1>
                                                    </Col></Row></Container>
                                            </div>
                                            <div className="usecase_overlay text-center">
                                                <p className="usecase_overlay_contents">
                                                    In the digital world, social media is a key tool to connect the people but security & privacy are the big challenges, here at Localisem we provide custom social media apps like Dating App, Messaging App, Profile Sharing App, Video streaming App, etc.  with scalable and security features to target your audience and give the safe options to exchange data between the people.
                    </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </Container>

                    </div>


                    <div className="offers">

                        <Container fluid>
                            <Row>
                                <Col xl={12}>
                                    <h1 className="offers-content text-center">We fervently craft attractive and impressive software solutions to increase your brand growth. </h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="why-lm-carousel">
                        <WhyLM />
                        <Container fluid>  <Row><Col lg={12}><NavHashLink to="/company#why" className=" readmore-link" >Read More<span class="center-con"><span className="round"><span id="cta"><span className="arrow primera next "></span><span className="arrow arrow1 next "></span><span className="arrow arrow2 next "></span></span></span></span></NavHashLink></Col></Row>
                        </Container>
                    </div>

                    <div className="prices" id="price">
                        <div className="price_sec" >
                            <div className="price_back_box" >
                                <Container fluid>

                                    <div className="price_header_sec">
                                        <div className="price_header_content">
                                            <h1 className="header-title" style={{ color: 'white' }}>Pricing</h1>
                                            <p className="price-content text-center">We value transparency and love to make you comfortable with our competitive and fair prices. There will be no surprise bills to surprise you. No Additional and unexpected expenses will be charged, until you approve the pre-approval invoice. That’s how we would like to be treated, and that is how our clients are treated, below sections will give you an insight about how much will be your product cost.</p>
                                        </div>                                </div>
                                </Container>
                            </div>
                        </div>


                        <Container fluid>
                            <div className="pricing" >
                                <div className="pricing_sec">
                                    <Row xl={5} lg={3} md={2} sm={1} xs={1} className="justify-content-md-center d-flex justify-content-center">

                                        <Col xl lg md={4} sm={6} xs={10} className="price_column" style={{ padding: '2px' }}>
                                            <Card className="price-card text-center mb-4" md={12} sm={12} style={{ border: '1px solid rgba(202, 202, 202,0.300) ' }}>
                                                <Card.Body>
                                                    <Card.Title><span className="price-header-title"> Free</span><div className="price_border"></div></Card.Title>

                                                    <Card.Text>
                                                        <p className="level">Starts at </p>
                                                        <div className="rating"><p>$0/<span className="duration">annum</span></p></div>
                                                    </Card.Text>
                                                    <Card.Text><NavHashLink to="/#contact"><button className="price-button">Get Started</button></NavHashLink></Card.Text>

                                                    <Card.Text className="mt-4">
                                                        <div className="card-subtitle"> <li>To honor below sectors</li></div>
                                                        <div className="items"><li className="item" >Farmers</li>
                                                            <li className="item" >Fisherman’s</li>
                                                            <li className="item" >Orphanage</li>
                                                            <li className="item"> Food donations ( Annadhanam )</li>
                                                            <li className="item">50% Discount for all NGO's</li></div>
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </Col>
                                        <Col xl lg md={4} sm={6} xs={10} className="price_column" style={{ padding: '2px' }}>
                                            <Card className=" price-card text-center mb-4" md={12} sm={12} style={{ border: '1px solid rgba(202, 202, 202,0.300) ' }}>
                                                <Card.Body>
                                                    <Card.Title><span className="price-header-title"> Website</span>  <div className="price_border"></div></Card.Title>

                                                    <Card.Text>
                                                        <p className="level">Starts at </p>
                                                        <div className="rating"> <p>$199/<span className="duration">annum</span></p></div>
                                                    </Card.Text>
                                                    <Card.Text><NavHashLink to="/#contact"><button className="price-button">Get Started</button></NavHashLink></Card.Text>

                                                    <Card.Text className="mt-4">
                                                        <div className="card-subtitle"> <li> Responsive website</li></div>
                                                        <div className="items"><li className="item" >Chat plugin </li>
                                                            <li className="item" >Business EmailId</li>
                                                            <li className="item" >Unlimited pages hosting </li>
                                                            <li className="item" >Unlimited images & videos</li>
                                                            <li className="item">24/7 Customer Support  </li></div>
                                                    </Card.Text>
                                                </Card.Body>


                                            </Card>
                                        </Col>
                                        <Col xl lg md={4} sm={6} xs={10} className="price_column" style={{ padding: '2px' }}>
                                            <Card className="price-card text-center mb-4" md={12} sm={12} style={{ border: '1px solid rgba(202, 202, 202,0.300) ' }}>
                                                <Card.Body>
                                                    <Card.Title><span className="price-header-title"> E-Commerce</span> <div className="price_border"></div></Card.Title>

                                                    <Card.Text>
                                                        <p className="level">Starts at </p>
                                                        <div className="rating"> <p>$299/<span className="duration">annum</span></p></div>
                                                    </Card.Text>
                                                    <Card.Text><NavHashLink to="/#contact"><button className="price-button">Get Started</button></NavHashLink></Card.Text>

                                                    <Card.Text className="mt-4">
                                                        <div className="card-subtitle"><li>Responsive website</li></div>
                                                        <div className="items"> <li className="item" >Admin Dashboard</li>
                                                            <li className="item">Vendor dashboard</li>
                                                            <li className="item" >Payment gateway integration</li>
                                                            <li className="item" >SMS gateway integration</li>
                                                            <li className="item">Chat plugin </li>
                                                            <li className="item" >Business email id</li>
                                                            <li className="item" >Unlimited pages hosting </li>
                                                            <li className="item" >Unlimited images & videos</li>

                                                            <li className="item">24/7 Customer Support </li></div>
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </Col>
                                        <Col xl lg md={4} sm={6} xs={10} className="price_column" style={{ padding: '2px' }}>
                                            <Card className="price-card text-center mb-4" md={12} sm={12} style={{ border: '1px solid rgba(202, 202, 202, 0.300) ' }}>
                                                <Card.Body>
                                                    <Card.Title><span className="price-header-title"> Mobile App</span> <div className="price_border"></div></Card.Title>

                                                    <Card.Text>
                                                        <p className="level">Starts at </p>
                                                        <div className="rating"> <p>$999/<span className="duration">annum</span></p></div>
                                                    </Card.Text>
                                                    <Card.Text><NavHashLink to="/#contact"><button className="price-button">Get Started</button></NavHashLink></Card.Text>

                                                    <Card.Text className="mt-4">
                                                        <div className="card-subtitle"><li>Cross platform supports</li></div>
                                                        <div className="items">
                                                            <li className="item"   >Enable any business into digital</li>
                                                            <li className="item"  >Payment gateway integration</li>
                                                            <li className="item"  >SMS gateway integration</li>
                                                            <li className="item"  >Push Notifications</li>
                                                            <li className="item" >Publish the App</li>
                                                            <li className="item" >Backend application hosting</li>
                                                            <li className="item"  >Chat plugin</li>
                                                            <li className="item"  >Unlimited images & videos</li>
                                                            <li className="item"  >24/7 Customer Support</li></div>
                                                    </Card.Text>
                                                </Card.Body>


                                            </Card>
                                        </Col>
                                        <Col xl lg md={4} sm={6} xs={10} className="price_column" style={{ padding: '2px' }}>
                                            <Card className="price-card text-center mb-4" md={12} sm={12} style={{ border: '1px solid rgba(202, 202, 202, 0.300) ' }}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <span className="price-header-title"> Enterprise</span>
                                                        <div className="price_border"></div>
                                                    </Card.Title>

                                                    <Card.Text>
                                                        <p className="level">Starts at </p>
                                                        <div className="rating"> <p>Custom Pricing</p></div>
                                                    </Card.Text>

                                                    <Card.Text><NavHashLink to="/#contact"><button className="price-button">Get Started</button></NavHashLink></Card.Text>
                                                    <Card.Text className="mt-4">
                                                        <div className="items">
                                                            <li className="item" >Please contact us for custom business solutions to get the best suited quote with free technical discussion and compliment UX design</li>
                                                        </div>
                                                    </Card.Text>
                                                </Card.Body>

                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Container>

                    </div>
                    <div className="contact">
                        <Container fluid>
                            <div className="contact_container" id="contact">
                                <Row>
                                    <Col lg={6}>
                                        <div className="contactform">
                                            <h1 className="header-title" >Contact Us</h1>
                                            <p className="text-center content" >Make Localisem your digital partner and it never disappoints you !!!</p>

                                            <ContactForm /></div></Col>
                                    <Col lg={6} className="contact-col my-auto"><Image className="contactimage my-auto" src={sample} /></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </Layout>

            </div>
        );
    }
}

export default Home;